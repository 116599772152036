.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.loading,
.error {
  text-align: center;
  font-size: 1.5em;
  color: #ff0000;
  margin-top: 20px;
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.btn {
  margin-right: 10px;
}

.btn-group .btn {
  margin-right: 5px;
}

.table-responsive {
  margin-top: 20px;
}

.table {
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 15px;
  vertical-align: middle;
  border: 1px solid #dee2e6;
}

.table th {
  background-color: #343a40;
  color: #fff;
  text-align: center;
}

.table td {
  text-align: center;
}

.thead-dark th {
  background-color: #343a40;
  color: #fff;
}

.table .btn-group .btn {
  margin-right: 5px;
}

.table .btn-group .btn:last-child {
  margin-right: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
