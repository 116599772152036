.container {
    max-width: 600px;
    margin: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.btn-block {
    width: 100%;
}
    