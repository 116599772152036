/* WebsiteCoordinatorsList.css */

.container {
    max-width: 1400px;
    margin: auto;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
}

.text-center {
    text-align: center;
}

.btn-primary {
    display: inline-block;
    background-color: #3498db;
    color: #ffffff;
    padding: 12px 24px;
    font-size: 18px;
    border-radius: 25px;
    text-decoration: none;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.btn-primary:hover {
    background-color: #2980b9;
}

.table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto; /* Adds horizontal scroll if needed */
}

.table th,
.table td {
    padding: 12px 24px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table thead tr {
    background-color: #f2f2f2;
}

.table tbody tr {
    transition: background-color 0.3s;
}

.table tbody tr:hover {
    background-color: #f2f2f2;
}

.table-bordered {
    border: 1px solid #ddd;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f2f2f2;
}
