:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --accent-color: #e74c3c;
  --text-color: #333;
  --bg-color: #ecf0f1;
  --white: #ffffff;
  --light-bg: rgba(236, 240, 241, 0.9); /* Light background for forms */
}

body {
  background-color: var(--bg-color);
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
}

.login-form-wrapper {
  background-color: var(--white);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px); /* Softens the background */
}

.login-form-wrapper::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  transform: rotate(45deg);
  z-index: -1;
}

.login-title {
  font-size: 36px;
  font-weight: 700;
  color: var(--secondary-color);
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.login-subtitle {
  font-size: 18px;
  color: var(--text-color);
  margin-bottom: 35px;
  text-align: center;
  position: relative;
}

.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--secondary-color);
}

.form-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.login-button {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.login-button:hover {
  background-color: #2980b9;
}

.login-button:active {
  transform: scale(0.95);
}

.error-message {
  color: var(--accent-color);
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
}

.forgot-password {
  text-align: center;
  margin-top: 20px;
}

.forgot-password a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.forgot-password a:hover {
  color: #2980b9;
}

.signup-link {
  text-align: center;
  margin-top: 30px;
  color: var(--text-color);
}

.signup-link a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.signup-link a:hover {
  color: #2980b9;
}

@media (max-width: 480px) {
 .login-form-wrapper {
    padding: 30px 20px;
  }
  
 .login-title {
    font-size: 28px;
  }
}
