/* UsersPage.css */

:root {
  --primary-color: #4e54c8;
  --secondary-color: #8f94fb;
  --text-color: #ffffff;
  --card-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  min-height: 100vh;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 40px;
}

.container {
  max-width: 1400px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.col-auto {
  flex: 0 0 auto;
}

.card {
  width: 300px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  box-shadow: var(--card-shadow);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(10px) brightness(0.8);
  transition: all 0.3s ease;
}

.card:hover::before {
  filter: blur(5px) brightness(0.9);
}

.card-icon {
  font-size: 48px;
  margin-bottom: 15px;
  color: var(--text-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.card-title a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}

.card-title a:hover {
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
}

.card-text {
  font-size: 36px;
  font-weight: 700;
  color: var(--text-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.l-bg-cherry { background: linear-gradient(135deg, #ff6b6b, #ee5253); }
.l-bg-blue-dark { background: linear-gradient(135deg, #373b44, #4286f4); }
.l-bg-green-dark { background: linear-gradient(135deg, #0a504a, #38ef7d); }
.l-bg-orange-dark { background: linear-gradient(135deg, #ff9966, #ff5e62); }
.l-bg-purple-dark { background: linear-gradient(135deg, #6a3093, #a044ff); }

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.card {
  animation: float 6s ease-in-out infinite;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 300px;
  }
}