@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root {
  --primary-color: #4361ee;
  --secondary-color: #3f37c9;
  --accent-color: #4895ef;
  --background-color: #f0f2f5;
  --text-color: #333;
  --text-secondary: #707075;
  --sidebar-width: 250px;
  --header-height: 60px;
  --transition-speed: 0.3s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: var(--background-color);
}

#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--sidebar-width);
  height: 100vh;
  background: linear-gradient(180deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: #fff;
  overflow-y: auto;
  transition: all var(--transition-speed) ease;
  z-index: 1000;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.sidebar-header h3 {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;
}

.sidebar-menu {
  padding: 0 15px;
}

.menu-item {
  margin-bottom: 10px;
}

.menu-item a, .menu-item button {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

.menu-item a:hover, .menu-item button:hover,
.menu-item a.active, .menu-item button.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.menu-item i {
  margin-right: 10px;
  font-size: 20px;
}

.sidebar-footer {
  margin-top: auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.user-profile {
  margin-bottom: 20px;
  color: black;
}

.user-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
}

.user-role {
  font-size: 1rem;
  color: black;

}

.logout-btn {
  width: 100%;
  padding: 10px 15px;
}

.logout-btn button {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-btn button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.logout-btn i {
  margin-right: 10px;
}

#content {
  margin-left: var(--sidebar-width);
  padding: 20px;
  transition: all var(--transition-speed) ease;
}

@media screen and (max-width: 768px) {
  #sidebar {
    width: 70px;
  }

  #sidebar .sidebar-header h3,
  #sidebar .menu-item span {
    display: none;
  }

  #sidebar .menu-item a, #sidebar .menu-item button {
    justify-content: center;
    padding: 15px;
  }

  #sidebar .menu-item i {
    margin-right: 0;
  }

  #content {
    margin-left: 70px;
  }

  .logout-btn button {
    padding: 10px;
  }

  .logout-btn span {
    display: none;
  }

  .sidebar-footer {
    padding: 10px;
  }

  .user-name, .user-role {
    font-size: 0.9rem;
    background-color: black;
  }
}

/* Scrollbar Styles */
#sidebar::-webkit-scrollbar {
  width: 5px;
}

#sidebar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

#sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}
