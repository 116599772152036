/* CoordinatorSidebar.css */

:root {
  --primary-color: #1a73e8;
  --secondary-color: #5f6368;
  --background-color: #ffffff;
  --hover-color: #f1f3f4;
  --active-color: #e8f0fe;
  --text-color: #3c4043;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

.coordinator-sidebar {
  width: 280px;
  background-color: var(--background-color);
  color: var(--text-color);
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 var(--shadow-color);
  transition: all 0.3s ease;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
}

.sidebar-header {
  padding: 24px 24px 8px;
  display: flex;
  align-items: center;
}

.sidebar-header h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: var(--primary-color);
}

.sidebar-menu {
  flex: 1;
  overflow-y: auto;
  padding-top: 8px;
}

.sidebar-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  margin: 0 8px 4px 8px;
  border-radius: 0 24px 24px 0;
}

.menu-item a {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  text-decoration: none;
  color: var(--secondary-color);
  transition: all 0.2s ease;
  border-radius: inherit;
}

.menu-item a:hover {
  background-color: var(--hover-color);
}

.menu-item.active a {
  background-color: var(--active-color);
  color: var(--primary-color);
}

.menu-item a i {
  margin-right: 18px;
  font-size: 20px;
  width: 24px;
  text-align: center;
}

.menu-item a span {
  font-size: 14px;
  font-weight: 500;
}

.user-profile {
  padding: 16px 24px;
  border-top: 1px solid var(--hover-color);
  background-color: var(--background-color);
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-name {
    color: black;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.user-role {
  color: black;
  margin: 0;
  font-size: 12px;
  color: var(--secondary-color);
}

.logout-btn {
  padding: 16px 24px;
  border-top: 1px solid var(--hover-color);
}

.logout-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 16px;
  border: none;
  background-color: var(--hover-color);
  color: var(--primary-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}

.logout-button:hover {
  background-color: var(--active-color);
}

.logout-button i {
  margin-right: 8px;
  font-size: 18px;
}

/* Scrollbar styling */
.sidebar-menu::-webkit-scrollbar {
  width: 8px;
}

.sidebar-menu::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-menu::-webkit-scrollbar-thumb {
  background: #dadce0;
  border-radius: 4px;
}

.sidebar-menu::-webkit-scrollbar-thumb:hover {
  background: #bdc1c6;
}

/* Responsive design */
@media (max-width: 768px) {
  .coordinator-sidebar {
    width: 64px;
  }

  .sidebar-header h3,
  .menu-item a span,
  .user-profile,
  .logout-button span {
    display: none;
  }

  .menu-item {
    margin: 0 4px 4px 4px;
  }

  .menu-item a {
    justify-content: center;
    padding: 12px;
  }

  .menu-item a i {
    margin-right: 0;
    font-size: 24px;
  }

  .logout-button {
    padding: 12px;
  }

  .logout-button i {
    margin-right: 0;
  }
}


.notification-badge {
    background-color: #ff4500;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 50%;
    margin-left: 10px;
}
