/* WorkRequestDetails.css */

.body {
  background-color: #f7f7f7;
  font-family: 'Roboto', sans-serif;
}

.container {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

h4 {
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.textField,
.select,
.datePicker,
.timePicker {
  margin-bottom: 20px;
}

.MuiTextField-root,
.MuiSelect-root,
.MuiDatePicker-root,
.MuiTimePicker-root {
  width: 100%;
}

.comment {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.client-comment {
  border-left: 4px solid #3f51b5;
}

.coordinator-comment {
  border-left: 4px solid #4caf50;
}

.comment-input {
  border-left: 4px solid #ff9800;
  padding-left: 10px;
  margin-top: 10px;
}

button {
  background-color: #3f51b5;
  color: #ffffff;
  border-radius: 4px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
}

button:hover {
  background-color: #303f9f;
}

.attachment,
.photos,
.videos {
  margin-top: 40px;
}

.attachment a {
  text-decoration: none;
  color: #3f51b5;
}

.photos img,
.videos video {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

img {
  width: 100%;
  height: auto;
}

video {
  width: 100%;
  height: auto;
}

@media (min-width: 600px) {
  .photos img,
  .videos video {
    width: calc(50% - 20px);
    margin-right: 20px;
  }
}
